import Box from "./Box";
import Loader from "./Loader";

export default function LoaderBox({
  label,
  isLoading,
}: {
  label?: string;
  isLoading?: boolean;
}) {
  return isLoading === undefined || isLoading === true ? (
    <Box mt={12} textAlign="center">
      <Loader />

      {label && <Box>{label}</Box>}
    </Box>
  ) : (
    <></>
  );
}
