import { Flex, Placeholder, TextField } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Headline2 from "../../libs/shared/components/Headline2";
import {
  CorePlace,
  getAddressSuggestionDetails,
  useAddressAutocomplete,
  useDestinations,
} from "../../hooks/useGuestDiscoveryApi";
import { borderBottomStyle } from "../commonStyles";
import { TextBold, TextMuted } from "../components/DiscoveryTypography";
import { useDiscoveryContext } from "../components/DiscoveryContext";
import { useNavigate, useSearchParams } from "react-router-dom";

const X_PADDING = 16;

export default function LocationBottomsheet({
  open,
  onSelected,
  onDismiss,
}: {
  open: boolean;
  onDismiss: () => void;
  onSelected?: (props: { place?: Partial<CorePlace>; city?: string }) => void;
}) {
  const { t } = useTranslation();
  const { setPlace, setSelectedMapCenter, setLocationBottomSheetOpen } =
    useDiscoveryContext();
  const {
    loading: loadingSuggestions,
    data: addressSuggestions,
    load: loadSuggestions,
  } = useAddressAutocomplete();
  const { data, loading } = useDestinations();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <BottomSheet
      onDismiss={onDismiss}
      open={open}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10]}
    >
      <Flex direction={"column"} style={borderBottomStyle}>
        <Flex direction={"column"}>
          <Flex padding={X_PADDING} style={{ ...borderBottomStyle }}>
            <Headline2>{t("Discovery.Location.Header")}</Headline2>
          </Flex>

          <Flex paddingLeft={X_PADDING} paddingRight={X_PADDING}>
            <TextField
              grow={1}
              label=""
              autoComplete="off"
              autoFocus={true}
              placeholder={t("Discovery.Location.Placeholder")}
              onChange={(e) => {
                loadSuggestions(e.target.value);
              }}
            ></TextField>
          </Flex>

          {(loading || loadingSuggestions) && (
            <Flex
              paddingLeft={X_PADDING}
              paddingRight={X_PADDING}
              direction={"column"}
            >
              <Placeholder height={30}></Placeholder>
              <Placeholder height={30}></Placeholder>
              <Placeholder height={30}></Placeholder>
            </Flex>
          )}

          {addressSuggestions.map((item) => (
            <Flex
              key={item.placeId}
              padding={16}
              direction={"column"}
              style={{ ...borderBottomStyle }}
              onClick={async () => {
                const place = await getAddressSuggestionDetails(item.placeId);

                if (place) {
                  setPlace(place);

                  navigate(`/s/${place.slug}?${searchParams.toString()}`);

                  setLocationBottomSheetOpen(false);

                  if (place.lat && place.lng) {
                    setSelectedMapCenter({
                      lat: Number(place.lat),
                      lng: Number(place.lng),
                    });
                  }

                  if (onSelected) {
                    onSelected({
                      place,
                    });
                  }
                }
              }}
            >
              <TextBold>{item.title}</TextBold>
              <TextMuted>{item.subtitle}</TextMuted>
            </Flex>
          ))}

          {data?.map((item) => (
            <Flex
              key={`result-${item.id}`}
              padding={16}
              style={{ ...borderBottomStyle }}
              onClick={() => {
                setLocationBottomSheetOpen(false);
                setSelectedMapCenter(undefined);
                setPlace(undefined);
                navigate(`/s/${item.id}?${searchParams.toString()}`);

                if (onSelected) {
                  onSelected({
                    city: item.id,
                  });
                }
              }}
            >
              {item.label}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </BottomSheet>
  );
}
